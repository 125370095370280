.App {
  margin:50px;
  text-align: center;
  background-color: #ffffff;
}

.App-logo {
  height: auto;
  width: 40vh;
}

.App-header {
  min-height: 20vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: start;
  font-size: calc(10px + 2vmin);
  color: white;
}


.panel {
  justify-content: start;
  flex-direction: column;
  align-items: center;
}

.top-margin {
  margin: 30px;
}

